var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t("views.sales.history.title"))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"12","md":"3"}},[_c('menu-date-picker',{attrs:{"date-picker-options":{
                allowedDates: _vm.allowedDates,
              },"input-options":{
                'prepend-inner-icon': 'mdi-calendar',
                'dense': true,
                'hide-details': true,
                'outlined': true,
                'label': _vm.$t('fields.startDate')
              }},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})],1),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"12","md":"3"}},[_c('menu-date-picker',{attrs:{"date-picker-options":{
                allowedDates: _vm.allowedDates
              },"input-options":{
                'prepend-inner-icon': 'mdi-calendar',
                'dense': true,
                'hide-details': true,
                'outlined': true,
                'label': _vm.$t('fields.endDate')
              }},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.zones,"item-text":"label","item-value":"value","label":_vm.$t('fields.zones'),"hide-details":"","dense":"","multiple":"","outlined":""},model:{value:(_vm.filters.zoneIds),callback:function ($$v) {_vm.$set(_vm.filters, "zoneIds", $$v)},expression:"filters.zoneIds"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.getSales}},[_vm._v(" "+_vm._s(_vm.$t("actions.filter"))+" ")])],1)],1),_c('v-row',[_c('btn-group-text',{attrs:{"title":_vm.$t('views.sales.history.filter'),"selected":_vm.selectedSalesFilter,"options":_vm.filterOptions,"name":"winning_ticket_control","color":"info","attribute":"name","translation-base":"views.sales.history.filterOptions","mandatory":""},on:{"change":_vm.filterChanged}})],1),_c('br'),_c('data-table',{staticClass:"stripped",attrs:{"dense":"","loading":_vm.loading,"headers":_vm.tableColumns,"items":_vm.effectiveSales},scopedSlots:_vm._u([{key:"item.totalSold",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(value))+" ")]}},{key:"item.totalPrizes",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(value))+" ")]}},{key:"item.commission",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(value))+" ")]}},{key:"item.netSale",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(value))+" ")]}},{key:"item.jackpot",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(value))+" ")]}},{key:"item.netEarning",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(value))+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th',[_vm._v("-")]),_c('th',[_vm._v(_vm._s(_vm.$formatter.asCurrency(_vm.sumField("totalSold"))))]),_c('th',[_vm._v(_vm._s(_vm.$formatter.asCurrency(_vm.sumField("totalPrizes"))))]),_c('th',[_vm._v(_vm._s(_vm.$formatter.asCurrency(_vm.sumField("netSale"))))]),_c('th',[_vm._v(_vm._s(_vm.$formatter.asCurrency(_vm.sumField("commission"))))]),_c('th',[_vm._v(_vm._s(_vm.$formatter.asCurrency(_vm.sumField("jackpot"))))]),_c('th',[_vm._v(_vm._s(_vm.$formatter.asCurrency(_vm.sumField("netEarning"))))])])])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }