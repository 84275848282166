<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          {{ $t("views.sales.history.title") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              class="pa-3"
              cols="12"
              md="3">
              <menu-date-picker
                v-model="filters.startDate"
                :date-picker-options="{
                  allowedDates: allowedDates,
                }"
                :input-options="{
                  'prepend-inner-icon': 'mdi-calendar',
                  'dense': true,
                  'hide-details': true,
                  'outlined': true,
                  'label': $t('fields.startDate')
                }" />
            </v-col>
            <v-col
              class="pa-3"
              cols="12"
              md="3">
              <menu-date-picker
                v-model="filters.endDate"
                :date-picker-options="{
                  allowedDates: allowedDates
                }"
                :input-options="{
                  'prepend-inner-icon': 'mdi-calendar',
                  'dense': true,
                  'hide-details': true,
                  'outlined': true,
                  'label': $t('fields.endDate')
                }" />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <v-autocomplete
                v-model="filters.zoneIds"
                :items="zones"
                item-text="label"
                item-value="value"
                :label="$t('fields.zones')"
                hide-details
                dense
                multiple
                outlined />
            </v-col>
            <v-col>
              <v-btn
                color="success"
                @click="getSales">
                {{ $t("actions.filter") }}
              </v-btn>
            </v-col>
          </v-row>
          <!-- Filter -->
          <v-row>
            <btn-group-text
              :title="$t('views.sales.history.filter')"
              :selected="selectedSalesFilter"
              :options="filterOptions"
              name="winning_ticket_control"
              color="info"
              attribute="name"
              translation-base="views.sales.history.filterOptions"
              mandatory
              @change="filterChanged" />
          </v-row>
          <br>
          <data-table
            class="stripped"
            dense
            :loading="loading"
            :headers="tableColumns"
            :items="effectiveSales">
            <template #item.totalSold="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalPrizes="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.commission="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.netSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.jackpot="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.netEarning="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template slot="body.append">
              <tr>
                <th>Total</th>
                <th>-</th>
                <th>{{ $formatter.asCurrency(sumField("totalSold")) }}</th>
                <th>{{ $formatter.asCurrency(sumField("totalPrizes")) }}</th>
                <th>{{ $formatter.asCurrency(sumField("netSale")) }}</th>
                <th>{{ $formatter.asCurrency(sumField("commission")) }}</th>
                <th>{{ $formatter.asCurrency(sumField("jackpot")) }}</th>
                <th>{{ $formatter.asCurrency(sumField("netEarning")) }}</th>
              </tr>
            </template>
          </data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getParams, getSalesHistory } from "@/api/sale";
import moment from "moment";
import { i18n } from "@/i18n";
import MenuDatePicker from "@/components/inputs/MenuDatePicker.vue";
import BtnGroupText from "../../components/inputs/BtnGroupText";
import DataTable from "@/components/DataTable.vue";

const WITHOUT_SALES = 0, WITH_SALES = 1, ALL = 2, filterOptions = [
  { id: WITHOUT_SALES, name: "withoutSales" },
  { id: WITH_SALES, name: "withSales" },
  { id: ALL, name: "all" },
];


function data() {
  return {
    sales: [],
    zones: [],
    filters: {
      startDate: moment().
      format("YYYY-MM-DD"),
      endDate: moment().
      format("YYYY-MM-DD"),
      zoneIds: [],
    },
    loading: false,
    selectedSalesFilter: WITH_SALES,
    filterOptions,
    WITH_SALES,
    WITHOUT_SALES,
    ALL,
  };
}

export default {
  name: "History",
  components: {
    DataTable,
    MenuDatePicker,
    BtnGroupText,
  },
  data,
  computed: {
    tableColumns() {
      return [
        {
          value: "bettingPool.reference",
          text: i18n.t("views.sales.daily.tableColumns.name"),
        },
        {
          value: "bettingPool.displayNumber",
          text: i18n.t("views.sales.daily.tableColumns.code"),
        },
        {
          value: "totalSold",
          text: i18n.t("views.sales.daily.tableColumns.totalSold"),
        },
        {
          value: "totalPrizes",
          text: i18n.t("views.sales.daily.tableColumns.totalPrizes"),
        },
        {
          value: "netSale",
          text: i18n.t("views.sales.daily.tableColumns.netSale"),
        },
        {
          value: "commission",
          text: i18n.t("views.sales.daily.tableColumns.commission"),
        },
        {
          value: "jackpot",
          text: "Jackpot",
        },
        {
          value: "netEarning",
          text: i18n.t("views.sales.daily.tableColumns.netEarning"),
        },
      ];
    },
    effectiveSales() {
      switch (this.selectedSalesFilter) {
        case WITHOUT_SALES:
          return this.sales.filter(historicSaleEntry => historicSaleEntry["netSale"] === 0);
        case WITH_SALES:
          return this.sales.filter(historicSaleEntry => historicSaleEntry["netSale"] !== 0);
        case ALL:
          return this.sales;
        default:
          return [];
      }
    },
  },
  created() {
    getParams().
    then(response => {
      this.zones = response.data.zones;
      this.filters.zoneIds = this.zones.map(zone => zone.value);
    });
  },
  methods: {
    sumField(key) {
      return this.sales.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0);
    },
    allowedDates(value) {
      const momentObj = moment(value, "YYYY-MM-DD").
      startOf("day");
      const tomorrowDate = moment().
      startOf("day").
      add(1, "days");

      return momentObj.isBefore(tomorrowDate);
    },
    getSales() {
      getSalesHistory(this.filters).
      then(response => {
        this.sales = response.data.sales;
      });
    },
    filterChanged(value) {
      this.selectedSalesFilter = value;
    },
  },
};
</script>

<style scoped>

</style>
